import React from 'react'
import dynamic from 'next/dynamic'

const NotReady = dynamic(() => import('@/components/NotReady'), { ssr: false })
const AppLogHome = dynamic(() => import('@/features/AppLogHome'), { ssr: false })
const AuditLogHome = dynamic(() => import('@/features/AuditLogHome'), { ssr: false })
const CmpGuide = dynamic(() => import('@/features/CmpGuide'), { ssr: false })
const CodeHome = dynamic(() => import('@/features/CodeHome'), { ssr: false })
const ColumnJusticeSearch = dynamic(() => import('@/features/ColumnJusticeSearch'), { ssr: false })
const ErrorCodesHome = dynamic(() => import('@/features/ErrorCodesHome'), { ssr: false })
const JobLogHome = dynamic(() => import('@/features/JobLogList'), { ssr: false })
const JobsHome = dynamic(() => import('@/features/JobList'), { ssr: false })
const MetaGlossarySearch = dynamic(() => import('@/features/MetaGlossarySearch'), { ssr: false })
const PageApiRole = dynamic(() => import('@/features/PageApiRoleHome'), { ssr: false })
const MetaWordSearch = dynamic(() => import('@/features/MetaWordSearch'), { ssr: false })
const NavMenusHome = dynamic(() => import('@/features/NavMenusHome'), { ssr: false })
const NavPagesHome = dynamic(() => import('@/features/NavPagesHome'), { ssr: false })
const PdsEdit = dynamic(() => import('@/features/PdsEdit'), { ssr: false })
const PdsHome = dynamic(() => import('@/features/PdsHome'), { ssr: false })
const CloverMainHome = dynamic(() => import('@/features/CloverMainHome'), { ssr: false })
const UserMgmtHome = dynamic(() => import('@/features/UserMgmtHome'), { ssr: false })
const RoleUserHome = dynamic(() => import('@/features/RoleUserHome'), { ssr: false })
const RoleSettingsHome = dynamic(() => import('@/features/RoleSettingsHome'), { ssr: false })
const ErrCodesHome = dynamic(() => import('@/features/ErrCodes'), { ssr: false })
const DataGridHome = dynamic(() => import('@/features/CloverDataGridHome'), { ssr: false })
const JeongSeo = dynamic(() => import('@/features/JeongSeo'), { ssr: false })
const JisuHome = dynamic(() => import('@/features/JisuHome'), { ssr: false })
const EduExmpHome = dynamic(() => import('@/features/EduExmpHome'), { ssr: false })
const EduExampleRefactoringHeejae = dynamic(
  () => import('@/features/EduExampleRefactoringHeejae'),
  { ssr: false },
)
// 상빈 fds 코드
const SbFdsCodeHome = dynamic(() => import('@/features/SbFdsCodeHome'), {
  ssr: false,
})

export interface ISceneComponent {
  url: string
  title: string
  component: React.ComponentType | React.ReactNode
}

/**
 * 배열을 받아 키가 url 이고 값이 {url, title, component} 인 객체를 리턴한다
 * reduce가 사용됐기 때문에 한 객체안에 전체 키와 값이 들어감 (배열아님)
 */
function createScenes(
  scenes: Array<[string, string, React.ComponentType]>,
): Record<string, ISceneComponent> {
  return scenes.reduce((acc, cur) => {
    const [url, title, component] = cur
    acc[url] = { url, title, component }
    return acc
  }, {} as Record<string, ISceneComponent>)
}

// TODO 제목에 비어 있는 부분 채워야 함
// 메인 레이아웃 안에 포함할 URL만 추가한다
export const SCENES: Record<string, ISceneComponent> = createScenes([
  ['/meta/words', '메타 단어', MetaWordSearch],
  ['/meta/glossary', '메타 용어', MetaGlossarySearch],
  ['/meta/column-justice', '컬럼 매핑 정의', ColumnJusticeSearch],
  ['/code', '코드 조회', CodeHome],
  ['/error-codes', '에러 코드 관리', ErrorCodesHome],
  ['/log/app-logs', '시스템 로그', AppLogHome],
  ['/log/audit-logs', 'AUDIT 로그', AuditLogHome],
  ['/log/job-logs', '잡 로그', JobLogHome],
  ['/log/jobs', '잡 목록', JobsHome],
  ['/nav/list', '메뉴 모음', NavMenusHome],
  ['/nav/pages', '페이지 목록', NavPagesHome],
  ['/pds/list', '자료실', PdsHome],
  ['/pds/edit', '자료실 등록', PdsEdit],
  ['/pds/edit/[pdsId]', '자료실 수정', PdsEdit],
  ['/cmp-guide', 'Component 가이드', CmpGuide],
  ['/page-api-role', '페이지 권한', PageApiRole],
  ['/index', '', CloverMainHome],
  ['/role/settings', '권한 설정', RoleSettingsHome],
  ['/role/user', '사용자 권한 관리', RoleUserHome],
  ['/clover-main', 'CLOVER 메인', CloverMainHome],
  ['/user-mgmt', '사용자관리', UserMgmtHome],
  ['/err-codes', '에러코드 관리', ErrCodesHome],
  ['/clover-data-grid', 'DATA GRID 고도화', DataGridHome],
  ['/jeongseo-page', '이정서', JeongSeo],
  ['/jisu-page-grid', '지수 교육 페이지', JisuHome],
  ['/edu-exp-tb', '교육 샘플 페이지', EduExmpHome],
  ['/edu-example-refactoring-heejae', '훅 수정 샘플 페이지', EduExampleRefactoringHeejae],
  // 상빈 fds 코드
  ['/sb-fds-code-home', '코드페이지-상빈', SbFdsCodeHome],
])

// url(키)로 값을 리턴해준다
export const findSceneByUrl = (url: string): ISceneComponent | undefined => {
  if (url.endsWith('/')) {
    url = url.replace(/\/+$/, '')
  }
  const scene = SCENES[url]
  return scene ?? undefined
}

// url컴포넌트를 받아서 리액트 노드로 변경해준다 기존 자연스럽게 되던거
export const createSceneElement = (
  component: React.ComponentType | React.ReactNode,
  props?: any,
): React.ReactNode | undefined => {
  if (React.isValidElement(component)) {
    return React.cloneElement(component, props)
  }

  const Comp = component as React.ComponentType
  return <Comp {...props} />
}
