import { PaletteColor } from '@mui/material'

export const switchingPalettes = [
  {
    main: '#e91e63',
    contrastText: '#fff',
    dark: '#a31545',
    light: '#ed4b82',
  },
  {
    main: '#f44336',
    contrastText: '#fff',
    dark: '#aa2e25',
    light: '#f6685e',
  },
  {
    main: '#ff5722',
    contrastText: '#fff',
    dark: '#c41c00',
    light: '#ff8a50',
  },
  {
    main: '#ff9800',
    contrastText: '#fff',
    dark: '#b26a00',
    light: '#ffac33',
  },
  {
    main: '#673ab7',
    contrastText: '#fff',
    dark: '#320b86',
    light: '#9a67ea',
  },
  {
    main: '#3f51b5',
    contrastText: '#fff',
    dark: '#002984',
    light: '#757de8',
  },

  {
    main: '#2196f3',
    contrastText: '#fff',
    dark: '#1769aa',
    light: '#4dabf5',
  },

  {
    main: '#03a9f4',
    contrastText: '#fff',
    dark: '#0276aa',
    light: '#35baf6',
  },

  {
    main: '#008ba3',
    contrastText: '#fff',
    dark: '#006172',
    light: '#62efff',
  },
  {
    main: '#009688',
    contrastText: '#fff',
    dark: '#00695f',
    light: '#33ab9f',
  },
  {
    main: '#4caf50',
    contrastText: '#fff',
    dark: '#357a38',
    light: '#6fbf73',
  },
  {
    main: '#8bc34a',
    contrastText: '#000',
    dark: '#618833',
    light: '#a2cf6e',
  },
  {
    main: '#cddc39',
    contrastText: '#000',
    dark: '#8f9a27',
    light: '#d7e360',
  },
  {
    main: '#ffeb3b',
    contrastText: '#000',
    dark: '#b2a429',
    light: '#ffef62',
  },
  {
    main: '#ffc107',
    contrastText: '#000',
    dark: '#b28704',
    light: '#ffcd38',
  },
  {
    main: '#9c27b0',
    contrastText: '#fff',
    dark: '#6a0080',
    light: '#d05ce3',
  },

  {
    main: '#795548',
    contrastText: '#fff',
    dark: '#4b2c20',
    light: '#a98274',
  },
  {
    main: '#9e9e9e',
    contrastText: '#000',
    dark: '#707070',
    light: '#cfcfcf',
  },
  {
    main: '#607d8b',
    contrastText: '#fff',
    dark: '#34515e',
    light: '#8eacbb',
  },
  {
    main: '#424242',
    contrastText: '#fff',
    dark: '#1b1b1b',
    light: '#6d6d6d',
  },
] as PaletteColor[]
